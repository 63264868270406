import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
// import FeatureIcons from 'molecules/FeatureIcons';
const Container = Styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  align-items: flex-start;
  gap: clamp(2rem, 3vw, 4rem);
  background-color: ${({ theme, cardColor }) => theme.colors[cardColor]};
  padding: clamp(2rem, 3vw, 4rem);

  ${({ theme }) => theme.mediaQueries.md} {
    align-items: center;
  }
`;
const Title = Styled.h3`
  ${({ theme }) => theme.mixins.textSubtitle};
  color: ${({ theme }) => theme.colors.white};
`;
const Feature = Styled.div`
  ${({ theme }) => theme.mixins.flexColCenter};
  align-items: flex-start;
  gap: 0.2rem;

  ${({ theme }) => theme.mediaQueries.md} {
    align-items: center;
  }
`;
const FeatureTitle = Styled.p`
  ${({ theme }) => theme.mixins.textP};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text};
`;
const FeatureDescription = Styled.p`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
  width: clamp(25rem, 40vw, 80rem);

  ${({ theme }) => theme.mediaQueries.md} {
    text-align: center;
  }
`;

const ApproachCard = ({ content }) => {
  const { stageTitle, featureTexts, cardColor } = content;
  return (
    <Container cardColor={cardColor}>
      {/* <FeatureIcons iconSrc={iconSrc} iconLabel="approach stage icon" /> */}
      <Title>{stageTitle}</Title>

      {featureTexts.map(({ featureTitle, featureDescription }, index) => (
        <Feature key={index}>
          <FeatureTitle>{featureTitle}</FeatureTitle>
          <FeatureDescription>{featureDescription}</FeatureDescription>
        </Feature>
      ))}
    </Container>
  );
};

ApproachCard.propTypes = {
  content: PropTypes.shape({
    stageTitle: PropTypes.string.isRequired,
    featureTexts: PropTypes.arrayOf(
      PropTypes.shape({
        featureTitle: PropTypes.string,
        featureDescription: PropTypes.string,
      })
    ),
  }),
};

export default ApproachCard;
