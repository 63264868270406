import styled from '@emotion/styled';
import Image from 'atoms/Image';
import TransitionWrapper from 'molecules/TransitionWrapper';

import content from 'data/home';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  position: relative;

  padding: 0 clamp(1rem, 6vw, 8rem);
  padding-top: clamp(4rem, 8vw, 8rem);
  padding-bottom: clamp(3rem, 4vw, 6rem);
`;

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.layouts.flexColCenter};

  gap: clamp(0.4rem, 0.6vw, 1rem);

  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`;

const Title = styled.h2`
  ${({ theme }) => theme.mixins.textSubtitle};
  font-size: clamp(4.4rem, 6vw, 9.6rem);
  text-transform: uppercase;
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.textSubtitle};
  font-size: clamp(1.6rem, 3vw, 3.6rem);
`;

const MapWrapper = styled.section`
  width: 100%;
  aspect-ratio: 16/9;
`;

const HomeLocations = () => {
  const { title, subtitle, locations } = content.locationsSection;
  return (
    <>
      <Container>
        <TransitionWrapper threshold={0}>
          <Content>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Content>
        </TransitionWrapper>
      </Container>
      <MapWrapper>
        <Image
          src="https://storage.googleapis.com/tiger-dc-web/assets/images/map/xxl.webp"
          label="data center locations map"
        />
      </MapWrapper>
    </>
  );
};

export default HomeLocations;
