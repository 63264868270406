import styled from '@emotion/styled';
import Image from 'atoms/Image';
import Button from 'atoms/Button';
import TransitionWrapper from 'molecules/TransitionWrapper';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  max-width: 115rem;
  gap: clamp(4rem, 5vw, 8rem);
`;
const ArticleCard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 4rem;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Thumbnail = styled.div`
  width: 100%;
  aspect-ratio: 3/2;
  max-height: 32rem;
  background-color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mixins.flexColCenter};
  overflow: hidden;
`;
const DefaultContent = styled.p`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins.textSubtitle};
  font-size: 6rem;
  span {
    color: ${({ theme }) => theme.colors.greenAccent};
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;

  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: flex-start;
  }
`;
const Headline = styled.div`
  ${({ theme }) => theme.mixins.textSubtitle};
`;
const Caption = styled.div`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  max-width: 75rem;
`;
const ButtonWrapper = styled.div`
  min-width: max-content;
  align-self: flex-end;
  margin-top: 1rem;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: auto;
  }

`;

const Newsfeed = ({ articles }) => {
  return (
    <Container>
      <Content>
        {articles.map((article, index) => (
          <ArticleCard key={index}>
            <TransitionWrapper threshold={0}>
              <Thumbnail>
                {article.thumbnail ? (
                  <Image
                    src={article.thumbnail}
                    label="article thumbnail image"
                  />
                ) : (
                  <DefaultContent>
                    <span>Tiger</span>DC
                  </DefaultContent>
                )}
              </Thumbnail>
            </TransitionWrapper>

            <CardContent>
              <TransitionWrapper>
                <Headline>{article.headline}</Headline>
              </TransitionWrapper>
              <TransitionWrapper>
                <Caption>{article.caption}</Caption>
              </TransitionWrapper>
              <ButtonWrapper>
                <TransitionWrapper threshold={0}>

                  <Button
                    url={article.sourceUrl}
                    label="Read More"
                    color="text"
                  />
                </TransitionWrapper>
              </ButtonWrapper>
            </CardContent>

          </ArticleCard>
        ))}
      </Content>
    </Container>
  );
};
export default Newsfeed;
