import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalContext';
import styled from '@emotion/styled';
import NavMenu from 'organisms/NavMenu';
import links from 'data/navigation';
import MobileMenuButton from 'molecules/MenuButtonMobile';
import NavMenuMobile from 'organisms/NavMenuMobile';
import useScrollPosition from '../../hooks/useScrollPosition';
import useNoScroll from 'hooks/useNoScroll';

const Container = styled.header`
  position: fixed;
  width: 100%;
  height: 6rem;
  padding: ${({ theme }) => theme.layouts.navPadding};
  padding-top: 0;
  padding-bottom: 0;
  background-color: ${({ theme, $scrolled }) =>
    $scrolled ? theme.colors.darkBlue : 'transparent'};
  z-index: ${({ theme }) => theme.zIndices.header};
  ${({ theme }) => theme.mixins.flexRowCenter};
  transition: background-color 0.3s ease;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.layouts.maxWidth};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.h1`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
`;

const AccentColor = styled.span`
  color: ${({ theme }) => theme.colors.greenAccent};
`;

const Header = () => {
  const { setMenuOpen, toggleMenu, menuOpen } = useContext(GlobalContext);
  useNoScroll(menuOpen);
  const scrolled = useScrollPosition(50);
  return (
    <Container $scrolled={scrolled}>
      <Content>
        <Logo>
          <Link to="/" onClick={() => setMenuOpen(false)}>
            <AccentColor>Tiger</AccentColor>DC
          </Link>
        </Logo>
        <MobileMenuButton onClick={toggleMenu} isOpen={menuOpen} />
        <NavMenu links={links} />
        <NavMenuMobile links={links} />
      </Content>
    </Container>
  );
};

export default Header;
