import { useState, useEffect, useRef } from 'react';

const useIntersectionObserver = ({
  threshold = 0.3,
  root = null,
  rootMargin = '0px',
} = {}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [hasIntersected, setHasIntersected] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (hasIntersected) return; // Prevent re-observing after first trigger

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          setHasIntersected(true); // Stop further updates
          observer.disconnect(); // Unobserve after first trigger
        }
      },
      { root, rootMargin, threshold }
    );

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
  }, [root, rootMargin, threshold, hasIntersected]);

  return { ref, isIntersecting };
};

export default useIntersectionObserver;
