import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import TransitionWrapper from 'molecules/TransitionWrapper';

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  align-items: flex-start;
  gap: clamp(0.5rem, 2vw, 1rem);

  ${({ theme }) => theme.mediaQueries.md} {
    align-items: center;
  }
`;

const Title = styled.h3`
  ${({ theme }) => theme.mixins.textH3};
  color: ${({ theme, titleColor }) => theme.colors[titleColor]};
  text-align: left;
  max-width: ${({ titleWidth }) => `${titleWidth}rem`};
  ${({ theme }) => theme.mediaQueries.md} {
    text-align: center;
  }
`;
const Caption = styled.p`
  width: 100%;
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
  text-align: left;
  max-width: ${({ subtitleWidth }) => `${subtitleWidth}rem`};
  ${({ theme }) => theme.mediaQueries.md} {
    text-align: center;
  }
`;

const HeadlineSection = ({
  titleWidth,
  subtitleWidth,
  sectionTitle,
  sectionSubtitle,
  titleColor,
}) => {
  return (
    <Content>
      <TransitionWrapper>
        <Title titleWidth={titleWidth} titleColor={titleColor}>
          {sectionTitle}
        </Title>
      </TransitionWrapper>
      <TransitionWrapper>
        {sectionSubtitle && (
          <Caption subtitleWidth={subtitleWidth}>{sectionSubtitle}</Caption>
        )}
      </TransitionWrapper>
    </Content>
  );
};

HeadlineSection.propTypes = {
  titleType: PropTypes.oneOf(['primary', 'secondary']),
  paddingPreset: PropTypes.oneOf(['topOfPage', 'insidePage']),
  titleWidth: PropTypes.number,
  subtitleWidth: PropTypes.number,
  bgColor: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  sectionTitle: PropTypes.string.isRequired,
  sectionSubtitle: PropTypes.string,
};

export default HeadlineSection;
