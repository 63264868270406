import styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';
import SectionHeader from 'molecules/SectionHeader';
import ProfileCard from 'molecules/ProfileCard';
import content from 'data/team';

import SEO from 'layouts/SEO';

const Page = styled.div`
  display: flex;
  flex-direction: column;
`;
const TeamWrapper = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  height: 100%;
  max-height: none;
`;
const ProfileCardsArea = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: auto;
  gap: clamp(2rem, 6vw, 8rem);
  width: 100%;
`;

const PageTop = styled.section`
  background-color: ${({ theme }) => theme.colors.black};
  padding-top: 5rem;
`;

const Team = () => {
  const { sectionTitle, sectionSubtitle } = content.headlineSection;
  const { cards } = content.teamSection;
  const { title, description, canonical } = content.seo;

  return (
    <Page>
      <SEO title={title} description={description} canonical={canonical} />
      <Header />
      <PageTop>
        <SectionHeader
          headline={sectionTitle}
          caption={sectionSubtitle}
          headlineColor="white"
        />
      </PageTop>
      <TeamWrapper>
        <Content>
          <ProfileCardsArea>
            {cards.map((card, index) => (
              <ProfileCard
                key={index}
                profileName={card.profileName}
                profilePosition={card.profilePosition}
                profileDescription={card.profileDescription}
                avatarSrc={card.profileImage}
              />
            ))}
          </ProfileCardsArea>
        </Content>
      </TeamWrapper>
      <Footer />
    </Page>
  );
};

export default Team;
