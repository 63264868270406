import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import Input from 'atoms/Input';
import Textarea from 'atoms/Textarea';
import FormContext from 'context/ContactForm';
import { ArrowRight } from 'lucide-react';

const ContactFormWrapper = styled.div`
  width: 100%;
  max-width: 100rem;
`;

const FormInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(3rem, 3vw, 4rem);
  button {
    align-self: flex-end;
  }
`;

const Icon = styled(ArrowRight)``;

const InputField = styled(Input)``;

const FormButton = styled.button`
  background: ${({ theme }) => theme.colors.black};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 0.8rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 1rem;
  ${({ theme }) => theme.mixins.textP};
  transition: background 0.2s ease;

  :hover {
    background: ${({ theme }) => theme.colors.greenAccent};
  }
`;

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const ContactForm = () => {
  const { formData, resetFormData } = useContext(FormContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setIsSubmitting(true);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact-form', ...formData }),
    })
      .then(response => {
        if (response.ok) {
          resetFormData();
        } else {
          throw new Error(`HTTP status ${response.status}`);
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error submitting form:', error);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <ContactFormWrapper>
      <form
        name="contact-form"
        aria-labelledby="contact-form"
        method="post"
        data-netlify="true"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <FormInputs>
          <InputField type="text" name="name" label="Name" />
          <InputField type="email" name="email" label="Email" />
          <InputField type="tel" name="phone" label="Phone" />
          <Textarea name="message" label="Message" maxLength={250} />
          <FormButton type="submit" disabled={isSubmitting} size="medium">
            {isSubmitting ? 'Sending...' : 'Send Message'} <Icon />
          </FormButton>
        </FormInputs>
      </form>
    </ContactFormWrapper>
  );
};

export default ContactForm;
