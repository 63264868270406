import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Icon from 'atoms/Icon';
import PropTypes from 'prop-types';

const solidStyle = ({ theme, color, hoverColor }) => css`
  background: ${theme.colors[color]};
  color: ${theme.colors.darkBlue};
  border: 2px solid ${theme.colors[color]};

  &:hover {
    background: ${theme.colors[hoverColor]};
    border-color: ${theme.colors[hoverColor]};
  }
`;

const outlineStyle = ({ theme, color, hoverColor }) => css`
  background: none;
  color: ${theme.colors[color]};
  border: 2px solid ${theme.colors[color]};

  &:hover {
    color: ${theme.colors[hoverColor]};
    border-color: ${theme.colors[hoverColor]};
  }
`;

const Container = styled.button`
  ${({ theme }) => theme.mixins.flexRowCenter}
  gap: 0.5em;
  padding: clamp(1rem, 0.8vw, 1.2rem) clamp(1.6rem, 1.2vw, 2.2rem);
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  ${({ theme }) => theme.mixins.textP};
  font-family: ${({ theme }) => theme.fonts.secondary};

  ${({ variant }) => variant === 'solid' && solidStyle}
  ${({ variant }) => variant === 'outline' && outlineStyle}
`;

const Button = ({
  url,
  label = 'Button',
  color = 'black',
  hoverColor = 'greenAccent',
  iconName = 'arrowRight',
  type = 'button',
  variant = 'outline',
}) => {
  return (
    <Link to={url} aria-label={label}>
      <Container
        color={color}
        hoverColor={hoverColor}
        type={type}
        variant={variant}
      >
        {label}
        <Icon iconName={iconName} type="solid" size="1x" />
      </Container>
    </Link>
  );
};

Button.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  iconName: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['solid', 'outline']),
};

export default Button;
