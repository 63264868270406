import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalContext';
import PropTypes from 'prop-types';

const NavLinks = styled.nav`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1.2rem;
  ${({ theme }) => theme.mixins.textNav};
  text-transform: uppercase;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.white};
  position: fixed;
  top: 0;
  left: 0;
  padding: 0rem clamp(1rem, 6vw, 8rem);
  margin-top: 6rem;
  transition:
    opacity 0.4s ease-in-out,
    visibility 0.4s ease-in-out;

  ${({ isOpen }) =>
    isOpen
      ? `
        visibility: visible;
        opacity: 1;
      `
      : `
        visibility: hidden;
        opacity: 0;
      `}

  ${({ theme }) => theme.mediaQueries.lg} {
    display: none;
  }
`;

const NavLink = styled(Link, {
  shouldForwardProp: prop => prop !== 'isOpen', // Prevents isOpen from being forwarded
})`
  transition: opacity 0.4s ease-in-out;
  transition-delay: ${({ delay }) => `${delay}s`};

  ${({ isOpen }) =>
    isOpen
      ? `
        opacity: 1;
      `
      : `
        opacity: 0;
      `}
`;

const BgOverlay = styled.div`
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.black};
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -999;
  transition:
    transform 0.6s ease-in-out,
    visibility 0.6s ease-in-out;
  transform-origin: top;

  ${({ isOpen }) =>
    isOpen
      ? `
        transform: scaleY(1);
        visibility: visible;
      `
      : `
        transform: scaleY(0);
        visibility: hidden;
      `}

  ${({ theme }) => theme.mediaQueries.lg} {
    display: none;
  }
`;

const NavMenuMobile = ({ links }) => {
  const { menuOpen, setMenuOpen } = useContext(GlobalContext);

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <NavLinks isOpen={menuOpen}>
        {links.map(({ url, label }, index) => (
          <NavLink
            key={url}
            to={url}
            onClick={handleLinkClick}
            aria-label={label}
            delay={menuOpen ? index * 0.06 : 0}
            isOpen={menuOpen} // Used only for styled-components, not passed to DOM
          >
            {label}
          </NavLink>
        ))}
      </NavLinks>
      <BgOverlay isOpen={menuOpen} aria-label="menu background overlay" />
    </>
  );
};

NavMenuMobile.propTypes = {
  links: PropTypes.array.isRequired,
};
export default NavMenuMobile;
