import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GlobalProvider } from 'context/GlobalContext';
import { ContactFormProvider } from 'context/ContactForm';
import RouteToTop from 'utils/RouteToTop';
import ScrollToHash from 'utils/ScrollToHash';
import theme from 'styles/theme';
import { HelmetProvider } from 'react-helmet-async';

// Pages
import Home from 'pages/Home';
import Approach from 'pages/Approach';
import Cooling from 'pages/Cooling';
import Contact from 'pages/Contact';
import Team from 'pages/Team';
import Partners from 'pages/Partners';
import Datacenters from 'pages/Datacenters';
import Location from 'pages/Location';
import News from 'pages/News';
import NotFound from 'pages/NotFound';

// Components
import ScrollToTop from 'organisms/ScrollToTop';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <RouteToTop />
        <ScrollToHash />
        <GlobalProvider>
          <ThemeProvider theme={theme}>
            <ContactFormProvider>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/approach" element={<Approach />} />
                <Route path="/cooling" element={<Cooling />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/team" element={<Team />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/datacenters" element={<Datacenters />} />
                <Route path="/datacenters/:location" element={<Location />} />
                <Route path="/news" element={<News />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ContactFormProvider>
          </ThemeProvider>
        </GlobalProvider>
      </Router>
    </HelmetProvider>
  );
};

export default App;
