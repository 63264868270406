import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalContext';
import PropTypes from 'prop-types';

const NavLinks = styled.nav`
  height: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: clamp(1rem, 2vw, 4rem);
  ${({ theme }) => theme.mixins.textNav};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;
  }
`;

const NavLink = styled(Link)`
  height: 100%;
  display: grid;
  place-content: center;
  position: relative;
  transition: color 0.4s ease;

  :hover {
    color: ${({ theme }) => theme.colors.greenAccent};
  }
  :hover span {
    transform: scaleX(1);
  }
`;

const Underline = styled.span`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.greenAccent};
  position: absolute;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: left;

  transition: transform 0.3s ease;
`;

const NavMenu = ({ links }) => {
  const { menuOpen, setMenuOpen } = useContext(GlobalContext); // Access context

  const handleLinkClick = () => {
    setMenuOpen(false); // Close the menu
  };

  return (
    <NavLinks isOpen={menuOpen}>
      {links.map(({ url, label }) => (
        <NavLink
          key={url}
          to={url}
          onClick={handleLinkClick}
          aria-label={label}
        >
          {label}
          <Underline aria-label="decorative link element"></Underline>
        </NavLink>
      ))}
    </NavLinks>
  );
};
NavMenu.propTypes = {
  links: PropTypes.array.isRequired,
};
export default NavMenu;
