const content = {
  seo: {
    title: 'TigerDC | Home',
    description:
      'AI Data Centers are the future with high density configurations, advanced GPU infrastructure, liquid cooling, high-speed networking, and unmatched scalability.',
    canonical: '/',
  },
  heroSection: {
    sectionTitles: ['Power the', 'AI', 'Future', '.'],
    sectionSubtitle: 'Let’s build the future, together.',
    backgroundImage:
      'https://storage.googleapis.com/tiger-dc-web/images/Aerial-View-Campus/l.webp',
  },
  featured: {
    title: 'SC1',
    subtitle: 'Our newest data center, located in South Carolina',
    features: [
      {
        label: 'Build-to-Suit SC1 100 MW AI DC in 18 months.',
        iconLabel: 'build-icon',
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/cooling-direct-w.svg?v=2',
      },
      {
        label: '50MW clean energy now, expandable to 100MW short-term.',
        iconLabel: 'energy-icon',
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/build-to-suit-w.svg',
      },
      {
        label: '2.5-hour drive from Atlanta’s central market.',
        iconLabel: 'location-icon',
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/electronics-w.svg',
      },
    ],
    buttonUrl: '/datacenters/sc1',
    buttonLabel: 'Explore SC1',
    bgImage:
      'https://storage.googleapis.com/tiger-dc-web/assets/images/SC2/BuildingEntrance/xxl.webp',
    bgImageLabel: 'image of the featured data center',
  },
  teamSection: {
    title: 'Our Team',
    subtitle:
      'Our management team possesses extensive expertise in finance, data center engineering, and project management. With decades of experience leading large-scale commercial real estate transactions, executing data center design and construction, and managing operations for top tech companies, we ensure the successful delivery of every data center project.',
    buttonUrl: '/team',
    buttonLabel: 'Meet the Team',
  },
  cooling: {
    title: 'Cooling',
    subtitle: 'Innovative Liquid & Air Solutions',
    features: [
      {
        label:
          'Strong Partnerships with Leading Vendors for AI Data Center Design',
        iconLabel: 'build-icon',
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/partnership-w.svg',
      },
      {
        label:
          'Expertise in High-Density Cooling Solutions, Including Direct-to-Chip and Rear Door Cooling',
        iconLabel: 'strong partnerships',
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/cooling-indirect-w.svg',
      },
      {
        label: 'Proven Experience in Hosting NVIDIA GPUs',
        iconLabel: 'experience hosting gpus',
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/host-w.svg',
      },
    ],
    buttonUrl: '/cooling',
    buttonLabel: 'Learn More',
    bgImage:
      'https://storage.googleapis.com/tiger-dc-web/images/liquidCoolingBanner/xxl.webp',
    bgImageLabel: 'cooling background graphic',
  },
  locationsSection: {
    title: 'AI DC LOCATIONS',
    subtitle: '600 MW capacity secured in the AIDC pipeline.',
    locations: [
      { lat: 34.863082, lng: -82.01883, label: 'SC1' },
      {
        lat: 36.015146,
        lng: -80.23379,
        label: 'NC1',
      },
    ],
  },
  callToAction: {
    ctaImage:
      'https://storage.googleapis.com/tiger-dc-web/assets/images/SC2/Aerial/xl.webp',
  },
};
export default content;
