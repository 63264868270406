import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Image from 'atoms/Image';
import Icon from 'atoms/Icon';
// import BulletPoint from 'molecules/BulletPoint';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 3vw, 2rem);

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div``;
const TableLists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: ${({ theme }) => theme.layouts.pagePadding};
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 2em;
`;
const TableTitle = styled.h4`
  ${({ theme }) => theme.mixins.textSubtitle};
  color: ${({ theme }) => theme.colors.black};
`;

const TableList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ListTitle = styled.p`
  ${({ theme }) => theme.mixins.textP};
  font-weight: 700;
`;
const ListItems = styled.ul`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
  gap: clamp(0.4rem, 2vw, 0.8rem);
  ${({ theme }) => theme.mixins.flexColCenter};
  align-items: flex-start;
`;

const HighlightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  svg {
    color: ${({ theme }) => theme.colors.greenAccent};
  }
`;

const Highlight = styled.span`
  margin-left: 0.6em;
`;

const FeatureTable = ({
  tableImageSrc,
  tableImageLabel,
  tableTitle,
  tableLists,
}) => {
  return (
    <Container>
      <Table>
        <ImageWrapper>
          <Image src={tableImageSrc} label={tableImageLabel} fit="cover" />
        </ImageWrapper>
        <TableLists>
          <TableTitle>{tableTitle}</TableTitle>
          <>
            {tableLists.map((tableList, tableIndex) => (
              <TableList key={tableIndex}>
                <ListTitle>{tableList.listTitle}</ListTitle>
                <ListItems>
                  {tableList.listItems.map((listItem, itemIndex) => (
                    <HighlightWrapper key={itemIndex}>
                      <Icon iconName="check" />
                      <Highlight>{listItem}</Highlight>
                    </HighlightWrapper>
                  ))}
                </ListItems>
              </TableList>
            ))}
          </>
        </TableLists>
      </Table>
    </Container>
  );
};

FeatureTable.propTypes = {
  tableImageSrc: PropTypes.string.isRequired, // Image source is a required string
  tableImageLabel: PropTypes.string.isRequired, // Image label is a required string
  tableTitle: PropTypes.string.isRequired, // Table title is a required string
  tableLists: PropTypes.arrayOf(
    PropTypes.shape({
      listTitle: PropTypes.string.isRequired, // Each list has a title as a required string
      listItems: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, // Array of required strings for list items
    })
  ).isRequired, // Array of table lists, where each item follows the defined shape
};
export default FeatureTable;
