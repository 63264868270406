import styled from '@emotion/styled';
import Icon from 'atoms/Icon';

const Container = styled.table`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-collapse: collapse;

  color: ${({ theme }) => theme.colors.white};
`;

const HeaderWrapper = styled.thead`
  th:nth-child(1) {
    text-align: left;
  }
`;

const ColumnName = styled.th`
  border-left: 1px solid ${({ theme }) => theme.colors.white};
  height: clamp(6rem, 5vw, 10rem);
  padding: 0 clamp(2rem, 5vw, 8rem);

  ${({ theme }) => theme.mixins.textSubtitle};
  vertical-align: middle;

  span {
    color: ${({ theme }) => theme.colors.greenAccent};
  }
`;

const Row = styled.tr`
  height: clamp(6rem, 5vw, 10rem);

  td:nth-child(2) {
    background-color: ${({ theme }) => theme.colors.greenAccent};
  }
`;

const RowName = styled.th`
  padding: 1rem clamp(2rem, 5vw, 8rem);

  ${({ theme }) => theme.mixins.textP};
  text-align: left;
  vertical-align: middle;
`;

const RowIcon = styled.td`
  border-left: 1px solid ${({ theme }) => theme.colors.white};
  text-align: center;
  vertical-align: middle;

  background-color: ${({ theme }) => theme.colors.slate};
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mixins.textSubtitle};
`;

const TableComparison = ({ tableContent }) => {
  const { title, items } = tableContent;
  return (
    <Container>
      <HeaderWrapper>
        <tr>
          <ColumnName scope="col">{title}</ColumnName>
          <ColumnName scope="col">
            <span>Tiger</span>DC
          </ColumnName>
          <ColumnName scope="col">Others</ColumnName>
        </tr>
      </HeaderWrapper>
      <tbody>
        {items.map((label, index) => (
          <Row key={index}>
            <RowName scope="row">{label}</RowName>
            <RowIcon>
              <Icon iconName="check" />
            </RowIcon>
            <RowIcon>
              <Icon iconName="xmark" />
            </RowIcon>
          </Row>
        ))}
      </tbody>
    </Container>
  );
};

export default TableComparison;
