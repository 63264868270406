import { memo } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'hooks/useLocation';
import DataCenterLayout from 'layouts/DataCenterLayout';
import PageHero from 'molecules/PageHero';
import SectionHeader from 'molecules/SectionHeader';
import Banner from 'molecules/Banner';
import InfoGrid from 'organisms/InfoGrid';
import FeatureTable from 'organisms/FeatureTable';
import Image from 'atoms/Image';
import TransitionWrapper from 'molecules/TransitionWrapper';

const MemoizedInfoGrid = memo(InfoGrid);
const MemoizedFeatureTable = memo(FeatureTable);

import PageFeatureBanner from 'molecules/PageFeatureBanner';
import QuickSpecsBanner from 'molecules/QuickSpecsBanner';

const Section = styled.section`
  ${({ theme }) => theme.mixins.flexColCenter};
`;
const IntroSection = styled.section`
  background-color: ${({ theme }) => theme.colors.black};
`;

const MapWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
`;

const Location = () => {
  const { config } = useLocation();

  if (!config) {
    return null;
  }

  const {
    contentTop: { topHeadline, topCaption },
    banner: { bannerSrc, bannerAltLabel },
    infoGrid: { gridTitle, gridItems },
    featureTable: {
      tableHeadline,
      tableCaption,
      tableImageSrc,
      tableImageLabel,
      tableTitle,
      tableLists,
    },
    locationsMap: { mapTitle, mapCaption, mapLocations },
    infoGrid2: { grid2Title, grid2Caption, grid2Items },
  } = config.about;

  const welcomeSection = config.welcomeSection;
  const featureBanner = config.featureBanner;
  const specSummaries = config.specSummaries;

  return (
    <>
      <DataCenterLayout>
        <PageHero
          content={welcomeSection}
          highlightIndex={2}
          alignLines={[1, 2]}
        />

        <QuickSpecsBanner specSummaries={specSummaries} />

        <IntroSection>
          <SectionHeader
            headline={topHeadline}
            caption={topCaption}
            headlineColor="white"
          />
          <Banner src={bannerSrc} alt={bannerAltLabel} />
        </IntroSection>

        <TransitionWrapper>
          <MemoizedInfoGrid title={gridTitle} gridItems={gridItems} />
        </TransitionWrapper>

        <PageFeatureBanner content={featureBanner} />

        <Section>
          <SectionHeader
            headline={tableHeadline}
            caption={tableCaption}
            headlineColor="black"
          />
          <MemoizedFeatureTable
            tableImageSrc={tableImageSrc}
            tableImageLabel={tableImageLabel}
            tableTitle={tableTitle}
            tableLists={tableLists}
          />
        </Section>

        <Section>
          <SectionHeader
            headline={mapTitle}
            caption={mapCaption}
            headlineColor="black"
          />
          <MapWrapper>
            <Image
              src="https://storage.googleapis.com/tiger-dc-web/assets/images/map/xxl.webp"
              label="data center locations map"
            />
          </MapWrapper>
        </Section>

        <Section>
          <MemoizedInfoGrid
            title={grid2Title}
            caption={grid2Caption}
            gridItems={grid2Items}
          />
        </Section>
      </DataCenterLayout>
    </>
  );
};

export default memo(Location);
