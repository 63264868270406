import styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';
import PageHero from 'molecules/PageHero';
import ApproachCard from 'organisms/ApproachCard';
import content from 'data/approach';
import SEO from 'layouts/SEO';

const ApproachWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardsArea = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  gap: 1rem;
`;

const Approach = () => {
  const stages = content.stages;
  const { title, description, canonical } = content.seo;

  return (
    <ApproachWrapper>
      <SEO title={title} description={description} canonical={canonical} />
      <Header />
      <PageHero content={content.hero} alignLines={[0, 1]} />
      <CardsArea>
        {stages.map((stage, index) => (
          <ApproachCard key={index} content={stage} />
        ))}
      </CardsArea>
      <Footer />
    </ApproachWrapper>
  );
};
export default Approach;
