const mixins = {
  flexColCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textH1: {
    fontFamily: "'Barlow', sans-serif",
    fontSize: 'clamp(7.2rem, 8vw, 12.8rem)',
    fontWeight: 800,
    letterSpacing: '-0.03em',
    lineHeight: '1.2em',
    textTransform: 'uppercase',
    marginTop: '-0.18em',
  },
  textH2: {
    fontFamily: "'Barlow', sans-serif",
    fontSize: 'clamp(6.4rem, 6vw, 9.6rem)',
    fontWeight: 800,
    letterSpacing: '-0.03em',
    marginTop: '-0.18em',
  },
  textH3: {
    fontFamily: "'Barlow', sans-serif",
    fontSize: 'clamp(3.2rem, 3vw, 4.8rem)',
    fontWeight: 800,
    letterSpacing: '-0.02em',
  },
  textSubtitle: {
    fontFamily: "'Barlow', sans-serif",
    fontSize: 'clamp(2rem, 3vw, 3.6rem)',
    fontWeight: 700,
    letterSpacing: '-0.02em',
  },
  textP: {
    fontSize: 'clamp(1.4rem, 2vw, 2rem)',
    fontFamily: "'Inter Tight', sans-serif",
    fontWeight: 400,
    letterSpacing: '0.015em',
    lineHeight: '1.4em',
  },
  textNav: {
    fontSize: 'clamp(1.4rem, 2vw, 1.6rem)',
    fontFamily: "'Barlow', sans-serif",
    fontWeight: 700,
    letterSpacing: '0.015em',
    lineHeight: '1.2em',
  },
};
export default mixins;
