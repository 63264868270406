import Styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';
import HomeHero from 'organisms/HomeHero';
import HomeFeatured from 'organisms/HomeFeatured';
import HomeTeam from 'organisms/HomeTeam';
import HomeLocations from 'organisms/HomeLocations';
import PartnersBar from 'molecules/PartnersBar';
import CtaBanner from 'molecules/CtaBanner';

import SEO from 'layouts/SEO';
import content from 'data/home';
import logos from 'data/logos';

const HomePageWrapper = Styled.div`
  display: flex;
  flex-direction: column;
`;

const Home = () => {
  const { featured, cooling } = content;
  const { ctaImage } = content.callToAction;
  const { title, description, canonical } = content.seo;

  return (
    <HomePageWrapper>
      <SEO title={title} description={description} canonical={canonical} />
      <Header />
      <HomeHero />
      <PartnersBar partners={logos.partners} />
      <HomeFeatured content={featured} />
      <HomeTeam />
      <HomeFeatured content={cooling} />
      <HomeLocations />
      <CtaBanner backgroundImage={ctaImage} />
      <Footer />
    </HomePageWrapper>
  );
};

export default Home;
