import styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';
import ContactForm from 'organisms/ContactForm';
import SectionHeader from 'molecules/SectionHeader';

import SEO from 'layouts/SEO';

const Container = styled.div`
  ${({ theme }) => theme.layouts.pageContainer};
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
`;

const ContactWrapper = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
const ContactWrapperStyled = styled.div`
  box-sizing: border-box;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PageTop = styled.section`
  background-color: ${({ theme }) => theme.colors.black};
  padding-top: 5rem;
`;

const Contact = () => (
  <>
    <SEO title="TigerDC | Contact" canonical="/contact" />
    <Header />
    <PageTop>
      <SectionHeader
        headline="Contact"
        caption="Get in touch with us today."
        headlineColor="white"
      />
    </PageTop>
    <Container>
      <Content>
        <ContactWrapper>
          <ContactWrapperStyled>
            <ContactForm />
          </ContactWrapperStyled>
        </ContactWrapper>
      </Content>
    </Container>
    <Footer />
  </>
);

export default Contact;
