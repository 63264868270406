const content = {
  hero: {
    sectionTitles: ['Press Releases', '&', 'News', '.'],
    sectionSubtitle: 'Stay up to date with the latest news at TigerDC.',
    backgroundImage:
      'https://storage.googleapis.com/tiger-dc-web/assets/images/BuildingEntrance/xl.webp',
  },
  articles: [
    {
      headline: 'New 100+ MW Data Center Begins Development',
      caption:
        'TigerDC is developing a 100+MW data center in South Carolina’s Tyger River Industrial Park, strategically positioned along I-85 between Atlanta and Charlotte, scheduled to go live in Q1 2026.',
      thumbnail:
        'https://img.datacenterfrontier.com/files/base/ebm/datacenterfrontier/image/2024/11/673cebe3ec59dd8268879897-tigerdcentrance.png?auto=format,compress&fit=crop&q=45&h=667&height=667&w=1200&width=1200',
      sourceUrl:
        'https://www.datacenterfrontier.com/white-papers/whitepaper/55244106/powering-the-future-tigerdcs-sustainable-data-center-in-tyger-river-industrial-park?preview-mode=true',
    },
    {
      headline: 'Mehmet Akcin Appointed to Board of Directors',
      caption:
        'Mehmet Akcin has shaped the backbone of global connectivity through pivotal roles at hyperscalers such as Microsoft and Yahoo, where he spearheaded strategic network infrastructure initiatives.',
      thumbnail:
        'https://mma.prnewswire.com/media/2620629/mehmet_akcin.jpg?w=600',

      sourceUrl:
        'https://www.prnewswire.com/news-releases/tigerdc-appoints-mehmet-akcin-to-its-board-of-directors-to-accelerate-innovation-in-ai-ready-data-centers-302377250.html',
    },
    {
      headline: "Data Center Private Equity Dealmakers' Forum",
      caption:
        'A huge thank you to Information Management Network (IMN) for hosting such a valuable event at the Union League Club.',
      thumbnail:
        'https://media.licdn.com/dms/image/v2/D5622AQFPr37PrFKBXQ/feedshare-shrink_800/B56ZTiJdJ7HEAk-/0/1738960921927?e=2147483647&v=beta&t=HG5gnEdiPv28yHnQxNTzSq-73SLGMAw9FMKkz2BHBNg',
      sourceUrl:
        'https://www.linkedin.com/posts/tiger-dc_datacenters-ai-infrastructure-activity-7293730757397618689-kqay',
    },
    {
      headline:
        'Joshua Crass Appointed as Head of AI Data Center Project Development',
      caption:
        'With over two decades of experience across Google, Meta and CrowdStrike, Crass brings expertise in innovating data center development and operations to his new role at TigerDC.',
      thumbnail: '',
      sourceUrl:
        'https://www.newswire.com/news/tigerdc-appoints-head-of-ai-data-center-project-development-22506579',
    },
  ],
};
export default content;
