import styled from '@emotion/styled';
import HeadlineSection from 'molecules/HeadlineSection';
import content from 'data/cooling';
import TableList from 'molecules/TableList';
import TransitionWrapper from 'molecules/TransitionWrapper';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(2rem, 3vw, 4rem);
`;

const FutureProofSection = () => {
  const { sectionTitle, sectionSubtitle } = content.futureProofSection;
  const tableContent = content.futureProofSection.table;
  return (
    <Container>
      <Content>
        <HeadlineSection
          subtitleWidth={80}
          sectionTitle={sectionTitle}
          sectionSubtitle={sectionSubtitle}
        />
        <TransitionWrapper>
          <TableList tableContent={tableContent} />
        </TransitionWrapper>
      </Content>
    </Container>
  );
};
export default FutureProofSection;
