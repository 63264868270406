import styled from '@emotion/styled';

const Container = styled.table`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.black};
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;

  tbody tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.silver}1F;
`;

const HeaderWrapper = styled.thead`
  background-color: ${({ theme }) => theme.colors.silver};
  color: ${({ theme }) => theme.colors.white};
`;

const ColumnName = styled.th`
  height: clamp(6rem, 5vw, 10rem);
  padding: 0 0.4rem;
  border: 2px solid ${({ theme }) => theme.colors.black};
  border-bottom: none;
  ${({ theme }) => theme.mixins.textSubtitle};
  font-size: clamp(1.4rem, 3vw, 3.2rem);
  vertical-align: middle;
`;

const Row = styled.tr`
  ${({ theme }) => theme.mixins.textP};
  font-size: clamp(1rem, 2vw, 1.6rem);
  color: ${({ theme }) => theme.colors.silver};
  height: clamp(6rem, 5vw, 10rem);
`;

const Cell = styled.td`
  padding: clamp(2rem, 3vw, 4rem) clamp(0.5rem, 1vw, 1rem);
  border-right: 2px solid ${({ theme }) => theme.colors.black};
  vertical-align: top;
  text-align: left;
  ${({ theme }) => theme.mediaQueries.sm} {
    text-align: center;
  }
`;

const TableList = ({ tableContent }) => {
  const { columns, data } = tableContent;
  return (
    <Container>
      <HeaderWrapper>
        <tr>
          {columns.map((col, index) => (
            <ColumnName key={index}>{col}</ColumnName>
          ))}
        </tr>
      </HeaderWrapper>
      <tbody>
        {data.map((row, rowIndex) => (
          <Row key={rowIndex}>
            {columns.map((col, colIndex) => (
              <Cell key={colIndex}>{row[col]}</Cell>
            ))}
          </Row>
        ))}
      </tbody>
    </Container>
  );
};

export default TableList;
