import styled from '@emotion/styled';
import SEO from 'layouts/SEO';
import Header from 'organisms/Header';
import PageHero from 'molecules/PageHero';
import DatacentersTemplate from 'organisms/DatacentersTemplate';
import Footer from 'organisms/Footer';
import TransitionWrapper from 'molecules/TransitionWrapper';

import content from 'data/datacenters';

const StyledDatacenter = styled.div`
  ${({ theme }) => theme.mixins.flexColCenter};
  justify-content: flex-start;
`;

const Datacenters = () => {
  const { title, description, canonical } = content.seo;
  const heroContent = content.index;
  const datacenters = content.identifiers;
  return (
    <StyledDatacenter>
      <SEO title={title} description={description} canonical={canonical} />
      <Header />
      <PageHero content={heroContent} alignLines={[0, 1]} />
      {datacenters.map(location => {
        if (content[location]) {
          return (
            <TransitionWrapper threshold={0}>
              <DatacentersTemplate
                key={location}
                location={location}
                data={content[location]}
              />
            </TransitionWrapper>
          );
        }
      })}
      <Footer />
    </StyledDatacenter>
  );
};

export default Datacenters;
