import styled from '@emotion/styled';
import content from 'data/home';
import Button from 'atoms/Button';
import TransitionWrapper from 'molecules/TransitionWrapper';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};

  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(1rem, 2vw, 2rem);
`;
const Title = styled.h2`
  ${({ theme }) => theme.mixins.textH2};
  text-transform: uppercase;
`;
const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.silver};

  padding-bottom: clamp(1rem, 2vw, 2rem);

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 84rem;
    text-align: center;
  }
`;

const HomeTeam = () => {
  const { title, subtitle, buttonUrl, buttonLabel } = content.teamSection;

  return (
    <Container id="team">
      <TransitionWrapper>
        <Content>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Button url={buttonUrl} label={buttonLabel} color="silver" />
        </Content>
      </TransitionWrapper>
    </Container>
  );
};

export default HomeTeam;
