import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalContext';
import links from 'data/navigation';
import Icon from 'atoms/Icon';

const Container = styled.footer`
  ${({ theme }) => theme.layouts.pageContainer};
  padding: ${({ theme }) => theme.layouts.navPadding};
  background-color: ${({ theme }) => theme.colors.white};

  a {
    transition: color 0.4s ease;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.greenAccent};
  }
`;

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};

  ${({ theme }) => theme.mixins.textNav};
  color: ${({ theme }) => theme.colors.silver};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    padding: 1.2rem 0;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    a {
      padding: 0.3rem 0;
    }
  }
`;

const Meta = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 500;

  align-self: stretch;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Logo = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const AccentColor = styled.span`
  color: ${({ theme }) => theme.colors.greenAccent};
`;

const PrimaryColor = styled.span`
  color: ${({ theme }) => theme.colors.black};
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.md} {
    gap: clamp(0.6rem, 1vw, 1.2rem);
  }
`;

const Copyright = styled(Link)`
  ${({ theme }) => theme.mixins.flexRowCenter};
  justify-content: flex-start;
  gap: 0.4em;
`;

const SiteMap = styled.nav`
  height: 100%;

  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-auto-flow: column;
  gap: 0 clamp(2rem, 5vw, 8rem);

  text-transform: uppercase;

  ${({ theme }) => theme.mediaQueries.md} {
    gap: clamp(0.6rem, 1vw, 1.2rem) clamp(2rem, 5vw, 8rem);
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const { setMenuOpen } = useContext(GlobalContext); // Access context

  const handleLinkClick = () => {
    setMenuOpen(false); // Close the menu
  };

  return (
    <Container>
      <Content>
        <Meta>
          <Logo to="/" onClick={() => setMenuOpen(false)}>
            <AccentColor>Tiger</AccentColor>
            <PrimaryColor>DC</PrimaryColor>
          </Logo>
          <Contact>
            <a href="mailto:info@tigerdc.com">info@tigerdc.com</a>

            <Copyright to="/" onClick={() => setMenuOpen(false)}>
              <Icon iconName="copyright" />
              {currentYear} TigerDC
            </Copyright>
          </Contact>
        </Meta>
        <SiteMap>
          {links.map(({ url, label }) => (
            <Link
              key={url}
              to={url}
              onClick={handleLinkClick}
              aria-label={label}
            >
              {label}
            </Link>
          ))}
        </SiteMap>
      </Content>
    </Container>
  );
};

export default Footer;
