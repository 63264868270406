const content = {
  seo: {
    title: 'Tiger DC | Cooling',
    description:
      'AI data centers are the future with high density configurations, advanced GPU infrastructure, liquid cooling, high-speed networking, and unmatched scalability.',
    canonical: '/cooling',
  },
  welcomeSection: {
    sectionTitles: ['AI Data Center', 'Cooling Design', '.'],
    sectionSubtitle:
      'Our AI data centers excel with advanced GPUs, liquid cooling, and scalability.',
    backgroundImage:
      'https://storage.googleapis.com/tiger-dc-web/images/dc-cooling-2/l.webp',
  },
  comparisonSection: {
    sectionTitle: 'Purpose Built for AI',
    sectionSubtitle:
      'Our data centers offer a unique selection of hardware optimized for AI applications. Explore the table below to see how TigerDC compares with other data centers.',
    table: {
      title: 'Feature',
      items: [
        'Hosting Nvidia GPUs',
        'High-Density Racks (~100 kW)',
        'Direct-to-Chip Cooling',
        'InfiniBand Networking for Node Connection',
        'Easy Scalability',
        'Future-Proof Design',
      ],
    },
  },
  futureProofSection: {
    sectionTitle: 'Future-Proofed for Tomorrow',
    sectionSubtitle:
      'Ready for H100s and Blackwell, our data centers are equipped with the latest cooling technologies to handle your growing needs. Get a quick overview of our solutions here.',
    table: {
      columns: ['Solution', 'Description', 'Pro', 'Con'],
      data: [
        {
          Solution: 'Single-Phase Direct-to-Chip',
          Description: 'Liquid transfers heat to an exchanger',
          Pro: 'Efficient and easy implementation',
          Con: '~100 kW rack limit',
        },
        {
          Solution: 'Two-Phase Direct-to-Chip',
          Description: 'Phase-changing fluid transfers heat',
          Pro: 'Highly efficient',
          Con: 'Complex, costly',
        },
        {
          Solution: 'Rear Door Cooling',
          Description: 'Door removes heat from the rack’s back',
          Pro: 'Works with direct-to-chip',
          Con: 'Limited to air cooling',
        },
        {
          Solution: 'Immersion Cooling',
          Description: 'Servers submerged in dielectric liquid',
          Pro: 'Highest efficiency',
          Con: 'High cost, risk of server damage',
        },
      ],
    },
  },
  solutionsSection: {
    sectionTitle: 'Need More Detail?',
    sectionSubtitle:
      "We've got you covered. Learn more about our cooling solutions below.",
    solutions: [
      {
        imgSrc:
          'https://storage.googleapis.com/tiger-dc-web/images/dc-cooling-dtc/m.webp',
        imgLabel: 'image of the single phase direct to chip solution',
        imgFit: 'cover',
        infoTitle: 'Single-Phase Direct-to-Chip',
        infoSubtitle: 'Transfers heat to a circulating fluid',
        bgColor: 'slate',
        solutionTextColor: 'white',
        featureIconName: 'check',
        featureTexts: [
          'Uses a cold plate',
          'Fluid flows to cooling distribution unit (CDU)',
          'Ideal for AI data centers',
        ],
      },
      {
        imgSrc:
          'https://storage.googleapis.com/tiger-dc-web/images/dc-cooling-dtc-2/m.webp',
        imgLabel: 'image of the two phase direct to chip solution',
        imgFit: 'cover',
        infoTitle: 'Two-Phase Direct-to-Chip',
        infoSubtitle: 'Uses phase change for superior heat absorption',
        bgColor: 'charcoal',
        solutionTextColor: 'white',
        featureIconName: 'check',
        featureTexts: [
          'Fluid changes from liquid to vapor',
          'Vapor condenses back in CDU',
          'Ideal for ultra-dense AI data centers',
        ],
      },
      {
        imgSrc:
          'https://storage.googleapis.com/tiger-dc-web/images/dc-cooling-rdhx-1/m.webp',
        imgLabel: 'image of the rear door cooling solution',
        imgFit: 'cover',
        infoTitle: 'Rear Door Cooling',
        infoSubtitle: 'Utilizes a cooling door for heat management',
        bgColor: 'black',
        solutionTextColor: 'white',
        featureIconName: 'check',
        featureTexts: [
          "Installed at the server rack's rear",
          'Contains heat exchangers and fans',
          'Compatible with direct-to-chip systems',
        ],
      },
    ],
  },
};
export default content;
