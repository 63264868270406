import styled from '@emotion/styled';
import TransitionWrapper from 'molecules/TransitionWrapper';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  background-color: ${({ theme }) => theme.colors.black};
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexRowCenter};
  max-width: 90rem;
  justify-content: space-between;
  gap: 2rem;
`;
const Highlight = styled.div`
  ${({ theme }) => theme.mixins.flexColCenter};
`;
const Number = styled.p`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mixins.textH1}
  font-size: clamp(6.4rem, 7vw, 12.8rem);
`;
const Label = styled.p`
  color: ${({ theme }) => theme.colors.greenAccent};
  ${({ theme }) => theme.mixins.textH1}
  font-size: clamp(2rem, 3vw, 4rem);
`;

const QuickSpecsBanner = ({ specSummaries }) => {
  return (
    <Container>
      <Content>
        {specSummaries.map((specSummary, index) => (
          <TransitionWrapper key={index}>
            <Highlight>
              <Number>{specSummary.number}</Number>
              <Label>{specSummary.label}</Label>
            </Highlight>
          </TransitionWrapper>
        ))}
      </Content>
    </Container>
  );
};
export default QuickSpecsBanner;
