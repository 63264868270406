import React from 'react';
import styled from '@emotion/styled';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

const TransitionWrapperStyled = styled.div`
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition:
    opacity 0.8s ease,
    transform 0.8s ease;
  transform: ${({ $visible, animation }) => {
    switch (animation) {
      case 'slide':
        return $visible ? 'translateX(0)' : 'translateX(8rem)';
      case 'scale':
        return $visible ? 'scale(1)' : 'scale(1.2)';
      case 'zoomIn':
        return $visible ? 'scale(1)' : 'scale(0.8)';
      default:
        return $visible ? 'translateY(0)' : 'translateY(8rem)';
    }
  }};
`;

const TransitionWrapper = ({
  children,
  animation = 'fade',
  threshold = 0.3,
}) => {
  const { ref, isIntersecting } = useIntersectionObserver({ threshold });

  return (
    <TransitionWrapperStyled
      ref={ref}
      $visible={isIntersecting}
      animation={animation}
    >
      {children}
    </TransitionWrapperStyled>
  );
};

export default TransitionWrapper;
