const links = [
  { url: '/datacenters', label: 'Data Centers' },
  // { url: '/datacenters/sc0', label: 'SC0' },
  { url: '/datacenters/sc1', label: 'SC1' },
  // { url: '/datacenters/sc2', label: 'SC2' },
  // { url: '/datacenters/sc3', label: 'SC3' },
  // { url: '/datacenters/nc1', label: 'NC1' },
  // { url: '/datacenters/TX1', label: 'TX1' },
  { url: '/cooling', label: 'Cooling' },
  { url: '/approach', label: 'Approach' },
  { url: '/team', label: 'Team' },
  { url: '/partners', label: 'Partners' },
  { url: '/news', label: 'News' },
  { url: '/contact', label: 'Contact' },
];
export default links;
