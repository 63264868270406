import Header from 'organisms/Header';
import PageHero from 'molecules/PageHero';
import Newsfeed from 'organisms/Newsfeed';
import Footer from 'organisms/Footer';

import content from '../data/news';

const News = () => {
  const { hero, articles } = content;
  return (
    <>
      <Header />
      <PageHero content={hero} alignLines={[1, 2]} highlightIndex={2} />
      <Newsfeed articles={articles} />
      <Footer />
    </>
  );
};
export default News;
