import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import TransitionWrapper from 'molecules/TransitionWrapper';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  height: 80vh;
  max-height: 50rem;

  background-image: linear-gradient(
      to bottom,
      ${({ theme }) => `${theme.colors.darkBlue}A6`},
      ${({ theme }) => `${theme.colors.darkBlue}D9`}
    ),
    ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mediaQueries.md} {
    max-height: 70rem;
  }
`;

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 2vw, 2rem);
  margin-top: 4rem; //find better solution later
`;

const TitleWrapper = styled.div`
  ${({ theme }) => theme.mixins.textH1};
  font-size: clamp(4.5rem, 8vw, 12.8rem);

  .aligned {
    display: inline-block;
  }
`;

const TitleLine = styled.h1`
  height: 1em;
  width: max-content;
  margin-right: 0.2em;
  display: block;
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.textSubtitle};
  color: ${({ theme }) => theme.colors.white};

  width: 25rem;

  ${({ theme }) => theme.mediaQueries.md} {
    width: clamp(25rem, 40%, 40rem);
  }
`;

const getWordColor = (word, index, highlightIndex) => {
  if (word === '.') return 'greenAccent';
  return index === highlightIndex ? 'white' : 'greenAccent';
};

const Word = styled.span`
  color: ${({ theme, color }) => theme.colors[color]};
  margin-right: ${({ isLast }) => (isLast ? '0' : '0.25rem')};
`;

const PageHero = ({ content, highlightIndex = 1, alignLines = [1, 2] }) => {
  const { sectionTitles, sectionSubtitle, backgroundImage } = content;

  // Group words into lines (word + optional period)
  const lines = sectionTitles.reduce((acc, word, i) => {
    if (word === '.' && i > 1) {
      acc[acc.length - 1].push(word);
    } else if (word !== '.') {
      acc.push([word]);
    }
    return acc;
  }, []);

  return (
    <Container backgroundImage={backgroundImage}>
      <Content>
        <TransitionWrapper>
          <TitleWrapper>
            {lines.map((line, lineIndex) => (
              <TitleLine
                key={lineIndex}
                className={alignLines.includes(lineIndex) ? 'aligned' : ''}
              >
                {line.map((word, wordIndex) => (
                  <Word
                    key={`${lineIndex}-${wordIndex}`}
                    color={getWordColor(word, lineIndex, highlightIndex)}
                    isLast={wordIndex === line.length - 1}
                  >
                    {word}
                  </Word>
                ))}
              </TitleLine>
            ))}
          </TitleWrapper>
        </TransitionWrapper>
        <TransitionWrapper>
          <Subtitle>{sectionSubtitle}</Subtitle>
        </TransitionWrapper>
      </Content>
    </Container>
  );
};

PageHero.propTypes = {
  highlightIndex: PropTypes.number,
  alignLines: PropTypes.arrayOf(PropTypes.number).isRequired,
};
export default PageHero;
