import styled from '@emotion/styled';
import content from 'data/home';
import Button from 'atoms/Button';
import TransitionWrapper from 'molecules/TransitionWrapper';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  min-height: 100dvh;

  background-image: linear-gradient(
      135deg,
      ${({ theme }) => `${theme.colors.darkBlue}BF`},
      ${({ theme }) => `${theme.colors.darkBlue}A6`},
      ${({ theme }) => `${theme.colors.darkBlue}73`},
      ${({ theme }) => `${theme.colors.darkBlue}1A`}
    ),
    ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  display: flex;
  flex-direction: column;
  gap: clamp(2rem, 3vw, 4rem);
  margin-top: 4rem; //find better solution later
`;

const TitleWrapper = styled.div`
  ${({ theme }) => theme.mixins.textH1};
  font-size: clamp(5.3rem, 8vw, 12.8rem);

  h1:nth-child(2) {
    display: inline-block;
  }

  h1:nth-child(3) {
    display: inline-block;
    margin-left: 0.2em;
  }
`;

const TitleLine = styled.h1`
  height: 1em;
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.textSubtitle};
  color: ${({ theme }) => theme.colors.white};

  width: 25rem;

  ${({ theme }) => theme.mediaQueries.md} {
    width: clamp(25rem, 40%, 35rem);
  }
`;

const ButtonWrapper = styled.div`
  width: max-content;
  margin-top: clamp(4rem, 8vw, 8rem);

  ${({ theme }) => theme.mediaQueries.md} {
    align-self: center;
  }
`;

const getWordColor = (word, index) => {
  if (word === '.') return 'greenAccent';
  return index === 1 ? 'white' : 'greenAccent';
};

const Word = styled.span`
  color: ${({ theme, color }) => theme.colors[color]};
  margin-right: ${({ isLast }) => (isLast ? '0' : '0.25rem')};
`;

const HomeHero = () => {
  const { sectionTitles, sectionSubtitle, backgroundImage } =
    content.heroSection;

  // Group words into lines (word + optional period)
  const lines = sectionTitles.reduce((acc, word, i) => {
    if (word === '.' && i > 0) {
      acc[acc.length - 1].push(word);
    } else if (word !== '.') {
      acc.push([word]);
    }
    return acc;
  }, []);

  return (
    <Container backgroundImage={backgroundImage}>
      <Content>
        <TransitionWrapper>
          <TitleWrapper>
            {lines.map((line, lineIndex) => (
              <TitleLine key={lineIndex}>
                {line.map((word, wordIndex) => (
                  <Word
                    key={`${lineIndex}-${wordIndex}`}
                    color={getWordColor(word, lineIndex)}
                    isLast={wordIndex === line.length - 1}
                  >
                    {word}
                  </Word>
                ))}
              </TitleLine>
            ))}
          </TitleWrapper>
        </TransitionWrapper>
        <TransitionWrapper>
          <Subtitle>{sectionSubtitle}</Subtitle>
        </TransitionWrapper>
        <ButtonWrapper>
          <TransitionWrapper>
            <Button
              url="#featured"
              label="See the Future"
              color="white"
              iconName="arrowDown"
            />
          </TransitionWrapper>
        </ButtonWrapper>
      </Content>
    </Container>
  );
};

export default HomeHero;
