import styled from '@emotion/styled';
import Button from 'atoms/Button';
import TransitionWrapper from 'molecules/TransitionWrapper';
import PropTypes from 'prop-types';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  position: relative;
  scroll-margin-top: 6rem; //find better method later
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  gap: clamp(2rem, 2vw, 4rem);
  span {
    color: ${({ theme }) => theme.colors.greenAccent};
  }
`;
const Title = styled.h2`
  ${({ theme }) => theme.mixins.textH2};
  font-size: clamp(3.4rem, 6vw, 9.6rem);
  text-transform: uppercase;
`;
const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.textH3};
  font-size: clamp(2rem, 3vw, 4.8rem);
`;
const ButtonsArea = styled.div`
  display: flex;
  gap: clamp(1rem, 2vw, 2rem);
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const BackgroundOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => `${theme.colors.darkBlue}A6`},
    ${({ theme }) => `${theme.colors.darkBlue}D9`}
  );
`;

const CtaBanner = ({
  backgroundImage,
  subtitle = 'Build Your Next Data Center With Us',
}) => {
  return (
    <Container>
      <Content>
        <TransitionWrapper threshold={0}>
          <Title>
            Power the future<span>.</span>
            <span></span>
          </Title>
          <Subtitle>{subtitle}</Subtitle>
        </TransitionWrapper>
        <TransitionWrapper threshold={0}>
          <ButtonsArea>
            <Button
              url="/team"
              label="Our Team"
              color="greenAccent"
              hoverColor="white"
            />
            <Button
              url="/contact"
              label="Contact Us"
              color="greenAccent"
              hoverColor="white"
              variant="solid"
            />
          </ButtonsArea>
        </TransitionWrapper>
      </Content>
      <Background backgroundImage={backgroundImage} aria-hidden="true">
        <BackgroundOverlay aria-hidden="true"></BackgroundOverlay>
      </Background>
    </Container>
  );
};
CtaBanner.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};
export default CtaBanner;
