import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from 'atoms/Button';
import ImageSlider from 'organisms/ImageSlider';
import Icon from 'atoms/Icon';

const Content = styled.section`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
`;
const FeatureTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: clamp(1rem, 3vw, 2rem);

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(2, 1fr);
`;

const ContentSection = styled.div`
  transition: transform 0.2s ease;
`;

const SliderSection = styled(ContentSection)`
  aspect-ratio: 16/9;

  @media (max-width: 1024px) {
    aspect-ratio: 4/3;
  }
`;

const HighlightsSection = styled(ContentSection)`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.header`
  padding: ${({ theme }) => theme.layouts.navPadding};
  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(0.4rem, 2vw, 0.8rem);
  text-align: center;
  width: clamp(36rem, 40vw, 80rem);
`;

const Title = styled.h2`
  ${({ theme }) => theme.mixins.textSubtitle};
  color: ${({ theme }) => theme.colors.black};
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
`;

const HighlightsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const HighlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: ${({ theme }) => theme.layouts.pagePadding};
  padding-top: 0;
  padding-bottom: 0;
`;

const HighlightItem = styled.li`
  ${({ theme }) => theme.mixins.textP};
  font-weight: 700;
`;

const HighlightDescription = styled.div`
  ${({ theme }) => theme.mixins.textP};
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  color: ${({ theme }) => theme.colors.text};
`;

const HighlightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  svg {
    color: ${({ theme }) => theme.colors.greenAccent};
  }
`;

const Highlight = styled.span`
  margin-left: 0.6em;
`;

const ExploreArea = styled.div`
  width: 100%;
  ${({ theme }) => theme.mixins.flexColCenter};
  padding: ${({ theme }) => theme.layouts.pagePadding};
`;

const DatacentersTemplate = ({ location, data }) => {
  const { overview, images, highlights } = data;

  return (
    <Content>
      <HeaderContainer>
        <Title>{overview.title}</Title>
        <Subtitle>{overview.subtitle}</Subtitle>
      </HeaderContainer>
      <FeatureTable>
        <SliderSection>
          <ImageSlider images={images.slider} />
        </SliderSection>
        <HighlightsSection>
          <HighlightsList>
            {highlights?.map(highlight => (
              <HighlightContainer key={highlight.id}>
                <HighlightItem>{highlight.title}</HighlightItem>
                <HighlightDescription>
                  {highlight.items?.map(item => (
                    <HighlightWrapper key={item}>
                      <Icon iconName="check" />
                      <Highlight key={item}>{item}</Highlight>
                    </HighlightWrapper>
                  ))}
                </HighlightDescription>
              </HighlightContainer>
            ))}
          </HighlightsList>
        </HighlightsSection>
      </FeatureTable>
      <ExploreArea>
        <Button
          url={`/datacenters/${location}`}
          label={`Explore ${location.toUpperCase()}`}
          iconName="arrowRight"
          color="silver"
        />
      </ExploreArea>
    </Content>
  );
};

DatacentersTemplate.propTypes = {
  location: PropTypes.string.isRequired,
  data: PropTypes.shape({
    overview: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }),
    images: PropTypes.shape({
      slider: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          title: PropTypes.string,
          description: PropTypes.string,
        })
      ).isRequired,
    }),
    highlights: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default DatacentersTemplate;
