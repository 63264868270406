import styled from '@emotion/styled';
import HeadlineSection from 'molecules/HeadlineSection';
import SolutionsFeatureSection from 'organisms/SolutionsFeatureSection';
import coolingConfig from 'data/cooling';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(2rem, 3vw, 4rem);
`;

const SolutionsSection = () => {
  const { sectionTitle, sectionSubtitle, solutions } =
    coolingConfig.solutionsSection;

  return (
    <Container>
      <Content>
        <HeadlineSection
          subtitleWidth={65}
          sectionTitle={sectionTitle}
          sectionSubtitle={sectionSubtitle}
        />
        <SolutionsFeatureSection solutions={solutions} />
      </Content>
    </Container>
  );
};
export default SolutionsSection;
